.error {
  color: inherit;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 10;
  height: 100%;
  width: 100%;

  backdrop-filter: blur(40px) saturate(100%);
  order: 0;
  flex: 1 1 auto;
  text-align: left;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  align-self: center;

  transition: all 1000ms linear;
}
