.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.image {
  flex: 0 0 200px;
  margin-bottom: 50px;
}
