.time {
  border: 1px solid #eaeaea;
  padding: 20px;
  margin-bottom: 8px;
  margin-right: 8px;
  border-radius: 8px;

  * {
    margin: 0px;
  }
}
