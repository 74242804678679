$breakpoint-tablet: 768px;
$max-width: 1156px;
$padding-xsmall: 0.5rem;
$padding-small: 1rem;
$padding-medium: 2rem;
$padding-large: 4rem;
$radius-medium: 4px;

$background-color: #f8fafc;
$primary-color: #000;
$inverted-primary-color: #fff;
$muted-color: rgba($primary-color, 0.75);
$secondary-color: #1f3bb2;
$accent-color-1: #fcc24f;
$accent-color-2: #25d9d9;
$accent-color-3: #ffc4dd;
$accent-color-4: #ff1263;
$accent-color-5: #A6EB9A;

$gray-100: #f5f5f5;
$gray-200: #eeeeee;
$gray-300: #e0e0e0;
$gray-400: #bdbdbd;
$gray-500: #9e9e9e;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;
