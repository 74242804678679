@import "variables";

html {
  touch-action: manipulation;
}

body {
  font-family: "Poppins", Arial, "Roboto", sans-serif;
  font-size: calc(10px + 1vmin);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

h1,
h2,
h3 {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

h4,
h5,
h6 {
  margin-block-start: 0rem;
  margin-block-end: 0rem;
}

p {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

label {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
}

input,
textarea {
  -webkit-user-select: text;
}

* {
  user-select: none;
}

.container {
  max-width: 960px;
}
