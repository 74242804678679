@import "variables";

.app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
// Styles for react-toast-notifications

.react-toast-notifications__container {
  .react-toast-notifications {
    &__toast {
      border-radius: 4px;
      width: 400px;
    }
    &__toast__icon {
      width: 24px;
      height: 24px;
    }
    &__toast__dismiss-icon {
      width: 24px;
      height: 24px;
    }
    &__toast__content {
      font-size: 20px;
    }
    &__toast__icon-wrapper {
      padding: 0 8px;
    }
    &__toast__icon-wrapper,
    &__toast__content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__toast__dismiss-button {
      padding: 10px 8px;
    }
  }
}
