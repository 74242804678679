@import "variables";

.offline {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-bottom: $padding-medium;

  h1 {
    color: $gray-400;
    font-weight: normal;
  }
}

.horizontalDivider {
  margin-left: $padding-xsmall;
  margin-right: $padding-xsmall;
  height: $padding-large;
  border: 1px solid $gray-400
}
